@tailwind base;
@tailwind components;
@tailwind utilities;

$primary: #39a5dd;
$black: #1d1e1c;
$text: #313333;
$pinkCK: #e2405f;
$grayCK: #f5f5f5;
$copyrightCK: #9b9b9b;

body {
    color: $text;
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3 {
    font-family: "Bebas Neue", sans-serif;
}

h1 {
    @apply text-7xl;
}

h2 {
    @apply text-5xl;
}

h3 {
    @apply text-4xl;
}

h4 {
    @apply text-2xl;
}

p {
    @apply mb-4;
}

.form-input,
.form-textarea {
    @apply border-0 text-text;
}

.btn {
    @apply flex justify-center items-center w-fit gap-3 py-4 px-6 font-bold text-sm leading-none tracking-wide uppercase transition-all duration-300;

    &.btn-primary {
        @apply text-white;

        box-shadow: $primary 0px 0px 0px 3em inset;

        &:hover {
            @apply text-primary;

            box-shadow: $primary 0 0 1px 2px inset !important;
        }
    }

    &.btn-black {
        @apply text-white;

        box-shadow: $black 0px 0px 0px 3em inset;

        &:hover {
            @apply text-black;

            box-shadow: $black 0 0 1px 2px inset !important;
        }
    }

    &.btn-pink {
        @apply text-white;

        box-shadow: $pinkCK 0px 0px 0px 3em inset;

        &:hover {
            @apply text-pinkCK;

            box-shadow: $pinkCK 0 0 1px 2px inset !important;
        }
    }
}

.line-sep {
    &::after {
        content: "";
        @apply flex border-b-2 border-primary w-36 my-8;
    }

    &.line-center {
        &::after {
            @apply md:mx-auto;
        }
    }
}

.header {
    @apply fixed top-0 bg-white w-full py-4;

    box-shadow: 0px 4px 18px 0px rgba(6, 26, 36, 0.07);

    .menu-icon {
        @apply md:hidden relative p-2 w-14 h-14 cursor-pointer z-30 transition-all duration-300;

        > span {
            @apply bg-primary absolute h-1 w-10 transition-all duration-300;

            &:nth-child(1) {
                top: 12px;
            }

            &:nth-child(2) {
                top: 26px;
            }

            &:nth-child(3) {
                bottom: 12px;
            }
        }

        &.open {
            > span {
                &:nth-child(1) {
                    @apply top-6;

                    -webkit-transform: rotate(135deg);
                    -moz-transform: rotate(135deg);
                    -o-transform: rotate(135deg);
                    transform: rotate(135deg);
                }

                &:nth-child(2) {
                    @apply opacity-0 scale-0;
                }

                &:nth-child(3) {
                    @apply top-6;

                    -webkit-transform: rotate(-135deg);
                    -moz-transform: rotate(-135deg);
                    -o-transform: rotate(-135deg);
                    transform: rotate(-135deg);
                }
            }
        }
    }

    .menu {
        @apply mt-8;

        ul {
            li {
                @apply font-bold text-sm uppercase;

                a {
                    @apply relative py-2 px-4 md:px-3 lg:px-4;

                    &::after {
                        @apply bg-primary absolute bottom-0 left-0 right-0 h-0.5 transition-all duration-300;
                        content: "";

                        visibility: hidden;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                    }

                    &:hover {
                        &::after {
                            visibility: visible;
                            -webkit-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }

        @media (max-width: 767.99px) {
            @apply fixed bg-white opacity-0 overflow-x-hidden h-full py-24 px-8 w-2/3 top-0 right-0 transition-opacity duration-300 pointer-events-none shadow-lg;

            &.open {
                @apply opacity-100 pointer-events-auto;
            }

            ul {
                li {
                    @apply text-xl;

                    a {
                        @apply block w-full;
                    }
                }
            }
        }
    }
}

.footer {
    @apply bg-black text-white px-8;
}

.slider {
    @apply bg-no-repeat bg-cover bg-bottom flex items-center justify-center px-8;

    height: calc(100vh - 80px);
    margin-top: 80px;

    @media (min-width: 768px) {
        height: calc(100vh - 100px);
        margin-top: 100px;
    }

    background-image: url("../public/images/slider-bg.jpg");

    @supports (background-image: url("../public/images/slider-bg.webp")) {
        background-image: url("../public/images/slider-bg.webp");
    }
}

.boxes {
    @apply -mt-20 px-8;
}

.about-us {
    @apply bg-no-repeat pt-24 md:pt-32;

    background-image: url("../public/images/connect4kids-bg.png");
    background-position: right 100px top 12rem;

    @media (min-width: 768px) {
        background-position: right 100px top 6rem;
    }

    @supports (background-image: url("../public/images/connect4kids-bg.webp")) {
        background-image: url("../public/images/connect4kids-bg.webp");
    }
}

.two-box {
    @apply bg-primary bg-no-repeat md:-mt-20 px-8;

    background-image: url("../public/images/4-bg.png");
    background-position: 80px bottom;

    @supports (background-image: url("../public/images/4-bg.webp")) {
        background-image: url("../public/images/4-bg.webp");
    }
}

.partners {
    @apply py-24 px-8;
}

.actions {
    @apply flex flex-col md:flex-row;

    .action-zis {
        @apply py-24 px-8 xl:p-40 md:w-3/6 flex flex-col items-start bg-no-repeat bg-cover text-white;

        background-image: url("../public/images/zdrowo-i-sportowo-bg.jpg");

        @supports (background-image: url("../public/images/zdrowo-i-sportowo-bg.webp")) {
            background-image: url("../public/images/zdrowo-i-sportowo-bg.webp");
        }
    }

    .action-sw {
        @apply py-24 px-8 xl:p-40 md:w-3/6 flex flex-col items-start bg-no-repeat bg-cover text-white;

        background-image: url("../public/images/sztuka-widzenia-bg.jpg");

        @supports (background-image: url("../public/images/sztuka-widzenia-bg.webp")) {
            background-image: url("../public/images/sztuka-widzenia-bg.webp");
        }
    }
}

.donation {
    @apply bg-grayCK px-8;
}

.bank-account {
    @apply px-8;
}

.contact {
    @apply flex flex-col md:flex-row;

    .contact-form {
        @apply py-24 px-8 xl:pl-40 xl:pr-24 2xl:pl-80 2xl:pr-40 md:w-7/12 flex flex-col items-start bg-no-repeat bg-cover text-white;

        background-image: url("../public/images/kontakt-bg.jpg");

        @supports (background-image: url("../public/images/kontakt-bg.webp")) {
            background-image: url("../public/images/kontakt-bg.webp");
        }

        .success {
            @apply font-medium bg-green-200 text-green-700 mb-4 p-4;
        }

        .error {
            @apply font-medium bg-red-200 text-red-700 mb-4 p-4;
        }
    }
}
